// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: 'hi',
    interpolation: {
      escapeValue: false, // React already safes from xss
    },
    resources: {
      en: {
        translation: {
          findYourIsuzuParts: 'Find Your ISUZU Auto Parts',
          partsSearchDescription: 'Experience hassle-free and precise auto parts search with our intuitive platform. Say goodbye to endless scrolling and frustration.',
          searchNow: 'Search Now',
          spareCarPartsService: 'SPARE CAR PARTS - AT YOUR SERVICE',
          givingYourCarMakeover: 'Giving Your Car the Makeover it Deserves',
          enquireYourIsuzuParts: 'Enquire Your ISUZU Auto Parts',
          exploreConvenience: 'Explore the convenience of sourcing your ISUZU auto parts hassle-free with us! Our platform offers a seamless and intuitive experience for all your ISUZU parts inquiries. Say goodbye to the complexities of hunting down specific components.',
          productEnquiry: 'PRODUCT ENQUIRY',
          partsAvailable: 'Parts for ISUZU',
          experienceYears: ' Years of experience',
          dailyCustomers: 'Daily Customers',
          uniqueParts: 'Unique Parts Avail',
          ourBusinessPartners: 'Our Business Partners',
          meetTrustedPartner: 'Meet our trusted partner, the one-stop destination for all your spare parts needs. With a vast inventory and expert knowledge, they keep your machinery running smoothly.',
          contactUs: 'CONTACT US',
          aboutUs: 'ABOUT US',
          haveQuestions: 'Have questions or need assistance? Contact us today! Our team is here to provide you with the support and guidance you need for all your auto parts inquiries. Whether you\'re seeking advice on product compatibility, have inquiries about our services, or simply want to reach out, we\'re here to help. Reach out through our contact form, give us a call, or send us an email.',
          contactUsNow: 'CONTACT US NOW',
          connectWithUs: 'Get connected with us on social networks:',
          homePage: 'HOME PAGE',
          searchInventory: 'SEARCH INVENTORY',
          findYourParts: 'FIND YOUR PARTS',
          searchProduct: 'SEARCH PRODUCT',
          searchProductSmall: 'Search Products',
          cantFind: 'Can\'t find what you\'re looking for? Contact us for assistance.',
          selectedProducts: 'Selected Products',
          partNumber: 'PART NUMBER',
          description: 'DESCRIPTION',
          quantity: 'QUANTITY',
          availableParts: 'AVAILABLE',
          quantitySelected: 'ACTION',
          checkOut: 'CHECK OUT',
          enquiryDetails: 'ENQUIRY DETAILS',
          labels: {
            nameCompany: 'Name / company',
            email: 'Email',
            mobileNumber: 'Mobile number',
            vinNumber: 'VIN number',
            yearModel: 'Year Model',
            vehicleName: 'Vehicle Name',
            comments: 'Comments',
            validName: "Enter a Valid name",
            nameRequired: "Name or Company Name is required",
            numberRequired: "Number is required",
            validNumber: "Please enter a valid Number",
            emailRequired: "Email is required",
            validEmail: "Please enter a valid email",
            vinRequired: "VIN number is required",
            validVin: "Please enter a valid VIN number",
            messageRequired: "Message is required",
            validMessage: "Please enter a valid Message",
            submitButton: 'Submit form',
          },
          placeholders: {
            fullName: "Enter Your Full Name",
            contactNumber: "Enter Your Contact Number",
            email: "Enter Your Email",
            vinNumber: "Enter VIN Number",
            message: "Enter Your Message Here"
          },
          testimonialsHeading: 'Testimonials',
          yearModel: 'Year Model',
          directLinks: "Direct Links",
          emailAddress: 'danyaautoparts@hotmail.com',
          phone1: '+966 554256020',
          phone2: '+966 16-3695005',    
          usefulLinks: "Useful Links",
          contact: "Contact",
          address: "King Fahd Rd, Buraydah, Saudi Arabia",
          submit: 'Submit',
          homePageTopSectionHeading: 'Your Reliable Spare Parts Partner For All ISUZU Parts & Aftermarket Quality Parts',
          welcomeMessage: 'DANYA AUTOPARTS WELCOMES YOU',
          premiumPartsSupplier: 'Your Premium Parts Supplier',
          welcomeText: 'Whether you’re looking to give your vehicle a makeover or you just need to buy a replacement part, Danya Autoparts is the best Spare Parts Retailer around! We’ve got what classic, fast, hard working and quality vehicle need to run further and make heads turn. As the number one in Al Qassim Region, we offer our clients and resellers quality auto and care products at competitive prices.',
          trustedSource: 'Danya Autoparts: Your Trusted Source for Quality Auto Parts',
          trustedSourceDetails: 'Danya Autoparts is your one-stop shop for all your vehicle needs, whether you\'re looking for replacement parts, upgrades, or car care products. We offer a wide selection of high-quality parts at competitive prices to keep your vehicle running smoothly and looking its best.',
          whyChooseUs: 'Why choose Danya Autoparts',
          extensiveSelection: 'Extensive selection: We carry a wide variety of parts for classic, performance, and everyday vehicles.',
          competitivePrices: 'Competitive prices: We offer the best value for your money on all our products.',
          qualityProducts: 'Quality products: We only stock parts from trusted brands to ensure quality and performance.',
          expertise: 'Expertise: Our team can help you find the right parts for your specific needs. Visit Danya Autoparts today and experience the difference!',
          havingTrouble: 'Having trouble with an ISUZU genuine part?',
          bestWarranty: 'Buy Auto Parts has the best warranty in the industry, without question. If you are experiencing any trouble with your part, if you need further assistance, feel free to call and talk to one of our experts.',
          informationReady: 'What information should I have ready when I call in a question or concern?',
          informationDetails: 'If you would like to call and speak to one of our experts, please have your invoice number ready so we can quickly find your file. Your invoice number is located on the confirmation page that was packaged with your part. If you provided an email address, this number can also be found in your confirmation emails.',
          needReplacement: 'If I need a replacement part, how long does it take to ship to me?',
          replacementDetails: 'In the rare case that your part is defective, we can ship you out a replacement auto part the same day in exchange for a temporary deposit. This deposit is to ensure that you send back your defective part and is fully refunded once it arrives back at our warehouse. We will also provide you with a free return shipping label to get the other part back to us.',
          ourVision: 'Our Vision',
          visionStatement: 'Being the best provider of services and high-quality auto parts for the automotive market.',
          ourMission: 'Our Mission',
          copyright: '© 2023 Copyright: ',
          rightsReserved: 'ALL RIGHTS RESERVED.',
          designedBy: 'Designed By: ',
          danyaAutoParts: 'Danya Auto Parts - Buraydah, Saudi Arabia.',
          designerName: 'Wasim Jagrala',
          missionStatement: 'To satisfy our customers by providing competitive products support for their businesses through a distribution network integrated.',
          ensureQuality: 'We ensure the highest quality to accomplish, so clients don’t take off.',
          testimonial1: 'I had an exceptional experience with Danya Autoparts! Their customer service is top-notch, and they went above and beyond to ensure I got the right parts for my car. The quality of their products is unmatched, and my car runs like new again. I highly recommend Danya Autoparts for anyone looking for reliable and high-quality auto parts.',
          testimonial2: "Danya Autoparts is a game-changer! From the moment I walked into their store, I was greeted with friendly faces and expert advice. They have an impressive inventory, and I found exactly what I needed without any hassle. Their prices are competitive, and the parts are durable. I'm a loyal customer now!",
          testimonial3: "I've been in the automotive industry for over a decade, and Danya Autoparts stands out as the best supplier I've ever worked with. Their commitment to quality and customer satisfaction is evident in everything they do. The parts I purchased were delivered on time and have performed exceptionally well. Kudos to the Danya Autoparts team!",
          testimonial4: "Finding a trustworthy auto parts supplier can be challenging, but Danya Autoparts exceeded all my expectations. Their team is knowledgeable, and they offer excellent support throughout the purchasing process. The parts I bought have significantly improved my vehicle's performance. I'm thoroughly impressed and will continue to do business with them.",
          findPartsTitle: 'Find Parts for Your Vehicle',
          searchPlaceholder: 'Example: 0011583000, 0012543000',
          cantFindText: "Can't find what you're looking for?",
          contactUsLink: 'Contact us',
          assistanceText: 'for assistance.',
          looksGoodFeedback: 'Looks good!',
          loadingData: 'Loading data...',
          noItemsFound: 'No items found.',
          addItemButton: 'Add Item',
          pleaseAddItem: 'Please add item to order',
          pageTitle: 'ISUZU Spare Parts now available on Delivery In Saudi Arabia',
          radioOptions: {
            individual: 'Individual',
            retailer: 'Retailer',
            wholesaler: 'Wholesaler',
          },
          validation: {
            nameCompanyRequired: 'Name or Company Name is required',
            nameCompanyPattern: 'Enter a Valid name',
            emailRequired: 'Email is required',
            emailPattern: 'Please enter a valid email',
            numberRequired: 'Number is required',
            numberPattern: 'Please enter a valid Number',
            vinRequired: 'VIN number is required',
            vinPattern: 'Please enter a valid VIN number',
            yearModelRequired: 'Year model is required',
            yearModelPattern: 'Please enter a valid Year model',
            vehicleNameRequired: 'Vehicle name is required',
            vehicleNamePattern: 'Please enter a valid vehicle Name',
            commentsRequired: 'Add a comment is required',
            validName: "Enter a Valid name",
            nameRequired: "Name or Company Name is required",
            validNumber: "Please enter a valid Number",
            validEmail: "Please enter a valid email",
            validVin: "Please enter a valid VIN number",
            messageRequired: "Message is required",
            validMessage: "Please enter a valid Message",
          },
          successMessage: 'Form submitted successfully!',
            accordion: {
            havingTrouble: 'Having Trouble?',
            havingTroubleText: "We understand that even the best parts can sometimes experience issues. If you're having trouble with your Isuzu genuine part, here's what to do:",
            contactExperts: "Contact our experts?",
            contactExpertsText: "Call us and speak to one of our knowledgeable team members. They can help diagnose the problem and answer any questions you may have.",
            haveInfo: "Have your information ready:",
            haveInfoText: "To help us quickly locate your purchase and assist you efficiently, please have your invoice number ready when you call. You can find your invoice number on the confirmation page that came with your part or in your confirmation email if you provided one.",
            replacementParts: "Replacement parts",
            replacementPartsTitle: "Here's how it works:",
              replacementPartsList1: "We will require a temporary deposit to ensure the return of the defective part.",
              replacementPartsList2:"This deposit will be fully refunded once we receive the returned part in our warehouse.",
              replacementPartsList3: "We will also provide you with a free return shipping label for your convenience.",
            replacementPartsText: "We are committed to providing you with the best possible service. Please don't hesitate to contact us if you have any questions or concerns."
          },
          getInTouch: 'GET IN TOUCH',
      getInTouchDescription:'Looking for professional advice and guidance on the best auto parts for your car? Get in touch to speak with one of our representatives and find out what your options are.',
      mapHeading1: 'Danya Auto Parts Branch 1',
      mapHeading2: 'Danya Auto Parts Branch 2 Comming Soon'

        },
      },
      ar: {
        translation: {
          findYourIsuzuParts: 'تدور عن قطع غيار سيارات ايسوزو ؟',
          spareCarPartsService: 'قطع غيار السيارات - في خدمتك',
          givingYourCarMakeover: 'نمنح سيارتك التجديد الذي تستحقه',
          enquireYourIsuzuParts: 'استفسر عن القطعه الي تبيها ISUZU الخاصة بك',
          exploreConvenience: 'اكتشف سهولة الحصول على قطع غيار سيارات ايسوزو دون أي متاعب معنا! يوفر موقعنا تجربة سلسة وبديهية لجميع استفساراتك عن قطع غيار ISUZU قل وداعًا لتعقيدات البحث عن مكونات معينة.',
          partsSearchDescription: 'استمتع بالبحث السلس والدقيق عن قطع غيار السيارات باستخدام منصتنا البديهية. وداعًا للتمرير اللامتناهي والإحباط.',
          searchNow: 'ابحث الآن',
          testimonialsHeading: 'شهادات العملاء',
          productEnquiry: 'للاستفسار عن المنتج',
          partsAvailable: ' قطعة متوفرة',
          experienceYears: 'سنة خبرة في قطع غيار ايسوزو',
          dailyCustomers: 'كل يوم زبون تقريبا واكثر في خدمتهم',
          uniqueParts: ' قطعه خاصة في شركتنا فقط',
          ourBusinessPartners: 'شركاؤنا التجاريون',
          homePageTopSectionHeading: ' شريكك في قطع الغيار القابلة لإعادة الشحن لجميع قطع غيار ايسوزو وقطع غيار ما بعد البيع ذات الجودة',
          meetTrustedPartner: 'تعرف على شريكنا الموثوق به، الوجهة الشاملة لجميع احتياجات قطع الغيار الخاصة بك. وبفضل المخزون الهائل والمعرفة المتخصصة، فإنهم يحافظون على تشغيل أجهزتك بسلاسة.',
          contactUs: 'اتصل بنا',
          emailAddress: 'danyaautoparts@hotmail.com',
          phone1: '+966 554256020',
          phone2: '+966 16-3695005',
          address: 'طريق الملك فهد، بريدة، المملكة العربية السعودية',
          haveQuestions: 'هل لديك أسئلة أو بحاجة إلى المساعدة؟ اتصل بنا اليوم! فريقنا موجود لتزويدك بالدعم والتوجيه الذي تحتاجه لجميع استفساراتك المتعلقة بقطع غيار السيارات. سواء كنت تبحث عن نصيحة بشأن توافق المنتج، أو لديك استفسارات حول خدماتنا، أو ببساطة تريد التواصل معنا، فنحن هنا لمساعدتك. تواصل معنا من خلال نموذج الاتصال الخاص بنا، أو اتصل بنا، أو أرسل لنا بريدًا إلكترونيًا.',
          contactUsNow: 'اتصل بنا',
          connectWithUs: 'تواصل معنا على شبكات التواصل الاجتماعي:',
          homePage: 'الصفحة الرئيسية',
          searchInventory: 'البحث عن القطعة',
          findYourParts: 'ابحث عن قطع غيار لسيارتك',
          searchProduct: 'ابحث عن القطعة',
          searchProductSmall: 'ابحث عن القطعة',
          directLinks: "روابط مباشرة",
          usefulLinks: "روابط مفيدة",
          contact: "اتصال",
          cantFind: 'ألا تستطيع إيجاد ما تبحث عنه؟ ابحث في موقعنا للحصول على المساعدة.',
          selectedProducts: 'منتجات مختارة',
          labels: {
            nameCompany: 'اسم/الشركة',
            email: 'البريد إلكتروني',
            mobileNumber: 'رقم الهاتف المحمول',
            vinNumber: 'رقم هيكل السيارة',
            yearModel: 'موديل السيارة',
            vehicleName: 'اسم السيارة',
            comments: 'تعليقات',
            submitButton: 'إرسال الاستمارة',
          },
          placeholders: {
            fullName: "ادخل الاسم الكامل",
            contactNumber: "أدخل رقم الهاتف الخاص بك",
            email: "أدخل بريدك الإلكتروني",
            vinNumber: "أدخل رقم VIN",
            message: "أدخل رسالتك هنا"
          },
          partNumber: 'رقم القطعه',
          aboutUs: 'نبذة عنا',
          description: 'اسم القطعه',
          quantity: 'الكمية',
          quantitySelected: 'الكمية المختارة',
          checkOut: 'الدفع',
          enquiryDetails: 'تفاصيل الإستفسار',
          submit: 'موافق',
          welcomeMessage: 'شركة دانية لقطع الغيار ترحب بكم',
          testimonial1: "لقد كانت تجربتي مع دانية لقطع غيار السيارات استثنائية! خدمة العملاء لديهم من الدرجة الأولى، وقد بذلوا قصارى جهدهم لضمان حصولي على الأجزاء الصحيحة لسيارتي. جودة منتجاتهم لا تضاهى، وسيارتي تعمل كأنها جديدة مرة أخرى. أوصي بشدة بدانية لقطع غيار السيارات لأي شخص يبحث عن قطع غيار موثوقة وعالية الجودة.",
          testimonial2: "دانية لقطع غيار السيارات غيرت اللعبة! من اللحظة التي دخلت فيها متجرهم، استقبلوني بوجوه ودية ونصائح خبراء. لديهم مخزون مثير للإعجاب، ووجدت بالضبط ما كنت أحتاجه دون أي عناء. أسعارهم تنافسية، والأجزاء متينة. أنا الآن عميل مخلص!",
          testimonial3: "لقد كنت في صناعة السيارات لأكثر من عقد، وتبرز دانية لقطع غيار السيارات كأفضل مورد تعاملت معه على الإطلاق. التزامهم بالجودة ورضا العملاء واضح في كل ما يفعلونه. الأجزاء التي اشتريتها تم تسليمها في الوقت المحدد وأداؤها ممتاز. تحية لفريق دانية لقطع غيار السيارات!",
          testimonial4: "العثور على مورد قطع غيار سيارات موثوق يمكن أن يكون تحديًا، لكن دانية لقطع غيار السيارات تجاوزت كل توقعاتي. فريقهم على دراية، ويوفر دعمًا ممتازًا طوال عملية الشراء. الأجزاء التي اشتريتها حسنت بشكل كبير من أداء سيارتي. أنا معجب جدًا وسأواصل العمل معهم.",        
          premiumPartsSupplier: 'مورد قطع الغيار المميزة لديك',
          welcomeText: 'سواء كنت تتطلع إلى تجديد سيارتك أو تحتاج فقط إلى شراء قطعة غيار بديلة، فإن شركة دانية هي أفضل بائع تجزئة لقطع الغيار على الإطلاق! لدينا ما تحتاج إليه السيارة الكلاسيكية والسريعة والمجتهدة وذات الجودة العالية للمضي قدمًا وجذب الأنظار. باعتبارنا الشركة الأولى في منطقة القصيم، فإننا نقدم لعملائنا وبائعي السيارات منتجات عالية الجودة للعناية بالسيارات وبأسعار تنافسية.',
          trustedSource: 'شركة دانية : مصدرك الموثوق لقطع غيار السيارات ذات الجودة العالية',
          trustedSourceDetails: 'شركة دانية هو متجرك الشامل لجميع احتياجات سيارتك، سواء كنت تبحث عن قطع غيار أو ترقيات أو منتجات للعناية بالسيارة. نحن نقدم مجموعة واسعة من قطع الغيار عالية الجودة بأسعار تنافسية للحفاظ على تشغيل سيارتك بسلاسة وتبدو في أفضل حالاتها.',
          whyChooseUs: 'لماذا اخترت شركة دانيا لقطع غيار السيارات؟',
          extensiveSelection: 'تشكيلة واسعة: لدينا مجموعة واسعة من قطع الغيار للمركبات الكلاسيكية والأداء والمركبات اليومية.',
          competitivePrices: 'أسعار تنافسية: نحن نقدم أفضل قيمة مقابل أموالك على جميع منتجاتنا.',
          qualityProducts: 'منتجات عالية الجودة: نقوم فقط بتخزين قطع الغيار من العلامات التجارية الموثوقة لضمان الجودة والأداء.',
          expertise: 'الخبرة: يمكن لفريقنا مساعدتك في العثور على الأجزاء المناسبة لاحتياجاتك الخاصة. قم بزيارة شركة دانية اليوم واستمتع بالفرق!',
          havingTrouble: 'هل تواجه مشكلة مع قطع غيار ايسوزو الأصلية؟',
          bestWarranty: 'شراء قطع غيار السيارات لديه أفضل ضمان في الصناعة، دون أدنى شك. إذا كنت تواجه أي مشكلة في الجزء الخاص بك، وإذا كنت بحاجة إلى مزيد من المساعدة، فلا تتردد في الاتصال والتحدث مع أحد خبرائنا.',
          informationReady: 'ما هي المعلومات التي يجب أن تكون جاهزة عند الاتصال بسؤال أو استفسار؟',
          informationDetails: 'إذا كنت ترغب في الاتصال والتحدث إلى أحد خبرائنا، فيرجى تجهيز رقم فاتورتك حتى نتمكن من العثور على ملفك بسرعة. يوجد رقم فاتورتك في صفحة التأكيد التي تم تعبئتها مع الجزء الخاص بك. إذا قدمت عنوان بريد إلكتروني، فيمكن العثور على هذا الرقم أيضًا في رسائل التأكيد الإلكترونية الخاصة بك.',
          needReplacement: 'إذا كنت بحاجة إلى قطع غيار، كم من الوقت يستغرق الشحن لي؟',
          replacementDetails: 'في الحالات النادرة التي يكون فيها الجزء الخاص بك معيبًا، يمكننا أن نشحن لك قطعة غيار سيارة بديلة في نفس اليوم مقابل إيداع مؤقت. يهدف هذا الإيداع إلى ضمان إعادة الجزء المعيب الخاص بك واسترداده بالكامل بمجرد وصوله إلى مستودعاتنا. سنزودك أيضًا بملصق شحن إرجاع مجاني لإعادة الجزء الآخر إلينا.',
          ourVision: 'رؤيتنا',
          visionStatement: 'أن نكون أفضل مزود للخدمات وقطع غيار السيارات عالية الجودة لسوق السيارات.',
          ourMission: 'مهمتنا',
          copyright: 'حقوق النشر © 2023: ',
          rightsReserved: 'جميع الحقوق محفوظة.',
          designedBy: 'التصميم بواسطة: ',
          danyaAutoParts: 'قطع غيار دانيا - بريدة، المملكة العربية السعودية.',
          designerName: 'وسيم جغرالة',
          missionStatement: 'لإرضاء عملائنا من خلال توفير دعم المنتجات التنافسية لأعمالهم من خلال شبكة توزيع متكاملة.',
          ensureQuality: 'نحن نضمن أعلى مستويات الجودة لإنجازها، حتى لا ينطلق العملاء.',
          findPartsTitle: 'ابحث عن قطع غيار لسيارتك',
          searchPlaceholder: 'مثال: 0011583000، 0012543000',
          cantFindText: 'لا تستطيع إيجاد ما تبحث عنه؟',
          contactUsLink: 'اتصل بنا',
          assistanceText: 'للحصول على المساعدة.',
          looksGoodFeedback: 'يبدو جيدًا!',
          availableParts: 'متاح',
          loadingData: 'جاري تحميل البيانات...',
          noItemsFound: 'لم يتم العثور على أي عناصر.',
          addItemButton: 'إضافة العنصر',
          pleaseAddItem: 'الرجاء إضافة العنصر إلى الطلب',
          pageTitle: 'قطع غيار إيسوزو متوفرة الآن للتوصيل في المملكة العربية السعودية',
          radioOptions: {
            individual: 'فردي',
            retailer: 'بائع تجزئة',
            wholesaler: 'تاجر الجملة',
          },
          validation: {
            nameCompanyRequired: 'اسم الشركة أو الفرد مطلوب',
            nameCompanyPattern: 'يرجى إدخال اسم صالح',
            emailRequired: 'البريد الإلكتروني مطلوب',
            emailPattern: 'الرجاء إدخال بريد إلكتروني صالح',
            numberRequired: 'رقم الهاتف مطلوب',
            numberPattern: 'الرجاء إدخال رقم هاتف صالح',
            vinRequired: 'رقم VIN مطلوب',
            vinPattern: 'الرجاء إدخال رقم VIN صالح',
            yearModelRequired: 'موديل السيارة مطلوب',
            yearModelPattern: 'الرجاء إدخال موديل سيارة صالح',
            vehicleNameRequired: 'اسم السيارة مطلوب',
            vehicleNamePattern: 'الرجاء إدخال اسم سيارة صالح',
            commentsRequired: 'التعليق مطلوب',
            validName: "أدخل اسمًا صحيحًا",
            nameRequired: "اسم الشركة مطلوب",
            validNumber: "الرجاء إدخال رقم هاتف صحيح",
            validEmail: "الرجاء إدخال بريد إلكتروني صحيح",
            validVin: "الرجاء إدخال رقم VIN صحيح",
            messageRequired: "الرسالة مطلوبة",
            validMessage: "الرجاء إدخال رسالة صالحة",
          },
          successMessage: 'تم إرسال الاستمارة بنجاح!',
          accordion: {
            havingTrouble: "هل تواجه مشاكل؟",
            havingTroubleText: "نحن نفهم أن حتى أفضل القطع قد تواجه أحيانًا مشكلات. إذا كنت تواجه مشكلة مع جزء إيسوزو الأصلي الخاص بك، إليك ما يجب القيام به:",
            contactExperts: "الاتصال بخبرائنا؟",
            contactExpertsText: "اتصل بنا وتحدث مع أحد أعضاء فريقنا المدربين تدريباً عالياً. يمكنهم تشخيص المشكلة والإجابة على أي أسئلة قد تكون لديك.",
            haveInfo: "أحضر معلوماتك جاهزة:",
            haveInfoText: "لمساعدتنا في تحديد موقع شرائك بسرعة ومساعدتك بكفاءة، يرجى إحضار رقم الفاتورة الخاص بك عند الاتصال. يمكنك العثور على رقم الفاتورة على الصفحة التأكيدية التي جاءت مع جزءك أو في بريدك الإلكتروني للتأكيد إذا قدمت واحداً.",
            replacementParts: "قطع الغيار البديلة",
            replacementPartsTitle: "هنا كيف يعمل الأمر:",
              replacementPartsList1:"سنحتاج إلى إيداع مؤقت لضمان إرجاع الجزء المعيب.",
              replacementPartsList2:"سيتم استرداد هذا الوديعة بالكامل بمجرد استلامنا للجزء المعاد في مستودعنا.",
              replacementPartsList3: "سنوفر لك أيضاً بطاقة شحن مجانية لإرجاعك لراحتك.",
            replacementPartsText: "نحن ملتزمون بتقديم أفضل خدمة ممكنة لك. لا تتردد في الاتصال بنا إذا كان لديك أي أسئلة أو استفسارات."
          },

          getInTouch: 'ابق على تواصل',
          getInTouchDescription: 'هل تبحث عن المشورة المهنية والتوجيه حول أفضل قطع غيار السيارات لسيارتك؟ تواصل معنا للتحدث مع أحد ممثلينا واكتشاف خياراتك.',
          mapHeading1: "فرع دانيا لقطع غيار السيارات 1",
          mapHeading2: "فرع دانيا لقطع غيار السيارات 2 قريبًا",

        },
      },
    },
  });

export default i18n;
