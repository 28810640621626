import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { Dialog } from "@headlessui/react";
import logo from "../../assets/New Logo.png";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next'; // Import the useTranslation hook

const NavBar = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation(); // Initialize useTranslation hook

  const handleMobileNavClick = (path) => {
    setMobileMenuOpen(false);
    navigate(path);
  };

  // Function to switch language
  const switchLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <>
      <header className="absolute inset-x-0 top-0 z-50">
        <nav className="flex items-center justify-between p-6 lg:px-8" aria-label="Global">
          <div className="flex items-center">
            <Link to="/" className="-m-1.5 p-1.5">
              <span className="sr-only">DANYA AUTOPARTS</span>
              <img className="h-8 w-auto" src={logo} alt="DANYA AUTOPARTS" />
            </Link>
          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="hidden lg:flex lg:flex-1 justify-center">
            <Link to="/" className="text-sm font-semibold decoration-transparent leading-6 text-gray-900 ml-4">
              {t('homePage')}
            </Link>
            <Link to="/searchInventory" className="text-sm font-semibold decoration-transparent leading-6 text-gray-900 ml-4">
              {t('searchInventory')}
            </Link>
            <Link to="/productEnquiry" className="text-sm font-semibold decoration-transparent leading-6 text-gray-900 ml-4">
              {t('productEnquiry')}
            </Link>
            <Link to="/aboutus" className="text-sm font-semibold decoration-transparent leading-6 text-gray-900 ml-4">
              {t('aboutUs')}
            </Link>
            <Link to="/contactus" className="text-sm font-semibold decoration-transparent leading-6 text-gray-900 ml-4">
              {t('contactUs')}
            </Link>
          </div>
          {/* Language switcher button for large screens */}
          <div className="hidden lg:flex items-center">
            <button onClick={() => switchLanguage('en')} className="text-black hover:text-red-500 mr-4">{t('English')}</button>
            <button onClick={() => switchLanguage('ar')} className="text-black hover:text-red-500">{t('Arabic')}</button>
          </div>
        </nav>
        <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
          <div className="fixed inset-0 z-50" />
          <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between">
              <Link to="/" className="-m-1.5 p-1.5" onClick={() => setMobileMenuOpen(false)}>
                <span className="sr-only">DANYA AUTOPARTS</span>
                <img className="h-8 w-auto" src={logo} alt="" />
              </Link>

                      {/* Language switcher buttons for mobile screens */}
            <div className="mt-6">
              <button onClick={() => switchLanguage('en')} className="w-full block py-2 px-4 text-sm text-left text-gray-900 hover:bg-gray-100">
                {t('English')}
              </button>
              <button onClick={() => switchLanguage('ar')} className="w-full block py-2 px-4 text-sm text-left text-gray-900 hover:bg-gray-100">
                {t('Arabic')}
              </button>
            </div>
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
                  <button
                    onClick={() => handleMobileNavClick('/')}
                    className="-mx-3 block w-full text-left rounded-lg px-3 py-2 text-base decoration-transparent font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    {t('homePage')}
                  </button>
                  <button
                    onClick={() => handleMobileNavClick('/searchInventory')}
                    className="-mx-3 block w-full text-left rounded-lg px-3 py-2 text-base decoration-transparent font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    {t('searchInventory')}
                  </button>
                  <button
                    onClick={() => handleMobileNavClick('/productEnquiry')}
                    className="-mx-3 block w-full text-left rounded-lg px-3 py-2 text-base decoration-transparent font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    {t('productEnquiry')}
                  </button>
                  <button
                    onClick={() => handleMobileNavClick('/aboutus')}
                    className="-mx-3 block w-full text-left rounded-lg px-3 py-2 text-base decoration-transparent font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    {t('aboutUs')}
                  </button>
                  <button
                    onClick={() => handleMobileNavClick('/contactus')}
                    className="-mx-3 block w-full text-left rounded-lg px-3 py-2 text-base decoration-transparent font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    {t('contactUs')}
                  </button>
                </div>
              </div>
            </div>
    
          </Dialog.Panel>
        </Dialog>
      </header>
    </>
  );
};

export default NavBar;
