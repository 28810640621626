import CarouselComp from '../carousel/CarouselComp';



const OurClients = () => {

  return (
    <div>
        <CarouselComp />
        </div>




  )
}

export default OurClients