import OurClients from "../HomePage/Last Section/OurClients";
import Stats from "../HomePage/MidSection/StatsCounterBar";
import Testimonials from "../HomePage/MidSection/Testimonials";
import AboutUsCarousel from "../HomePage/carousel/aboutUS/AboutUsCarousel";
import { Accordion } from 'react-bootstrap';
import { useTranslation } from "react-i18next";

const AboutUSPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="bg-white container mx-auto mt-20 mb-10 p-4">
        <div className="mb-10 text-center">
          <h1>{t('welcomeMessage')}</h1>
          <h3>{t('premiumPartsSupplier')}</h3>
          <p className="max-w-2xl mx-auto">
            {t('welcomeText')}
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-10 mb-10">
          <div>
            <AboutUsCarousel />
          </div>
          <div>
            <p>
            {t('trustedSource')}
            </p>

            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>{t('whyChooseUs')}</Accordion.Header>
                <Accordion.Body>
                  <ul>
                  <li>{t('extensiveSelection')}</li>
                    <li>{t('competitivePrices')}</li>
                    <li>{t('qualityProducts')}</li>
                    <li>{t('expertise')}</li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>{t('havingTrouble')}</Accordion.Header>
                <Accordion.Body>
                 {t('bestWarranty')}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>{t('informationReady')}</Accordion.Header>
                <Accordion.Body>
                  {t('informationDetails')}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>{t('needReplacement')}</Accordion.Header>
                <Accordion.Body>
                 {t('replacementDetails')}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-10 mb-10">
          <div>
            <p>
              {t('trustedSourceDetails')}
            </p>
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>{t('havingTrouble')}</Accordion.Header>
                <Accordion.Body>
                 {t('accordion.havingTroubleText')}     
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>{t('accordion.contactExperts')}</Accordion.Header>
                <Accordion.Body>
                {t('accordion.contactExpertsText')} 
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header> {t('accordion.haveInfo')} </Accordion.Header>
                <Accordion.Body>
                {t('accordion.haveInfoText')} 

                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>                {t('accordion.replacementParts')} 
</Accordion.Header>
                <Accordion.Body>
                  <b>                {t('accordion.replacementPartsTitle')} 
</b>
                  <ul>
                    <li>{t('accordion.replacementPartsList1')}  </li>
                    <li>{t('accordion.replacementPartsList2')}  </li>
                    <li>{t('accordion.replacementPartsList3')}  </li>
                  </ul>
                  {t('accordion.replacementPartsText')} 
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
          <div>
            <AboutUsCarousel />
          </div>
        </div>




        <div className="grid max-w-screen-xl items-center grid-cols-1 gap-8 p-4 mx-auto bg-red-500 text-black sm:grid-cols-1 xl:grid-cols-3 sm:p-8 ">
          <div className="flex flex-col items-center justify-center">
            <h4 className="text-3xl font-extrabold text-white">{t('ourVision')}</h4>
            <p className="text-xl font-bold text-white">
              {t('visionStatement')}
            </p>
          </div>
          <div className="flex flex-col items-center justify-center">
            <h4 className="text-3xl font-extrabold text-white">{t('ourMission')}</h4>
            <p className="text-xl font-bold text-white">
              {t('missionStatement')}
            </p>
          </div>
          <div className="flex flex-col items-center justify-center">
            <h4 className="text-3xl font-extrabold text-white">{t('ourVision')}</h4>
            <p className="text-xl font-bold text-white">
              {t('visionStatement')}
            </p>
          </div>
        </div>
        <div className="mb-10">
          <OurClients />
        </div>

        <div>
          <Stats />
        </div>
        <div className="relative isolate overflow-hidden bg-white px-6 py-5 sm:py-6 lg:px-8">
          <Testimonials />
        </div>
      </div>
    </>
  );
};

export default AboutUSPage;
